.nav {
    min-height: 125px;
    max-width: 1200px;
    margin: 0 auto;
}

.bottomNav {
    width: 100%;
    color: white;
    background-color: #171a21;
    display: block;
    position: relative;
    z-index: 5;
}

a {
    margin: 15px;
    color: #ccc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: #eee;
}

.navPic {
    margin: 0;
    padding: 0;
    margin-bottom: -10px;
    width: 125px;
    color: white;
    position: relative;
}

.navLink {
    margin: 15px;
    display: inline-block;
    margin-top: 50px;
    font-size: 18px;
    text-decoration: none;
    color: #ccc;
}

.navLink:nth-child(6) {
    margin-left: 50%;
}

.navLink:nth-child(7) {
    margin-left: 25%;
}

.hamburgMenu {
    display: none;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 30px;
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 5px;
    background-color: #ccc;
    margin: 6px 0;
    transition: 0.4s;
}

.changeBar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.changeBar2 {
    opacity: 0;
}

.changeBar3 {
    transform: translate(0, -11px) rotate(45deg);
}

.navLinksVisible {
    margin: 15px;
    text-decoration: none;
    color: #ccc;
    display: block;
    height: 50px;
    line-height: 50px;
}



/* Mobile-specific dropdown styles */
.dropdownContainer {
    display: inline-block;
}

.dropdownMenu {
    display: none; /* Hide dropdown by default */
    position: absolute;
    /*background-color: #384d66; */
    background-color: var(--link-blue);
    min-width: 160px; /* Set minimum width */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Add shadow for effect */
    z-index: 1; /* Ensure dropdown is above other elements */
    padding: 0; /* Optional: Add padding */
    transition: max-height 0.5s ease-in-out; /* Smooth transition for dropdown visibility */
}

/* Show dropdown menu when hovering over the dropdown container */
.dropdownContainer:hover .dropdownMenu {
    display: block; /* Display dropdown when hovering */
}

/* Add a class to show the dropdown menu in mobile view */
.dropdownMenuOpen {
    display: block; /* Show dropdown when open in mobile view */
}

/* Style each link inside dropdown */
.dropdownMenu a {
    color: black; /* Text color */
    padding: 12px 16px;
    margin: 0;
    margin-top: 15px; /* Padding inside each link */
    text-decoration: none; /* Remove underline */
    display: block; /* Make each link a block element */
}

/* Optional: Hover effect on dropdown links */
.dropdownMenu a:hover {
    /*background-color: #f1f1f1;*/ /* Change background on hover */
    background-color: var(--link-blue-hover);
}


.longUsername {
    margin-left: 5% !important; /* Adjust this as needed for long usernames */
}



@media (max-width: 1300px) {

    .navLink:nth-child(6) {
        margin-left: 30%;
    }

    .navLink:nth-child(7) {
        margin-left: 15%;
    }

    
}

@media (max-width: 1000px) {
    .navLink:nth-child(7) {
        margin-left: 15%;
    }
}

@media (max-width: 1050px) {
    .nav {
        overflow-x: auto;
        min-height: 100px;
    }

    .hamburgMenu {
        display: block;
    }

    .navPic {
        padding: 0;
        margin-top: 35px;
    }

    .navLink {
        height: 50px;
        text-align: center;
        line-height: 50px;
        margin: 30px;
        display: none;
    }

    .navLinksVisible {
        display: block;
    }

    .navLink:nth-child(6) {
        margin-left: 30px;
    }
    
    .navLink:nth-child(7) {
        margin-left: 30px;
    }

    
    /* Adjust margin-top for nav links when dropdown is open */
    .dropdownShift {
        margin-top: 300px; /* Adjust as needed */
        transition: margin-top 0.3s ease-in-out; /* Smooth transition for margin-top */
    }

    .dropdownContainer {
        width: 100%;
        height: 50px;
        display: block;
    }

    .dropdownContainerMobileOpen {
        margin-bottom: 325px;
        width: 100%;
        height: 50px;
        display: block;
    }

    .dropdownMenu {
        position: relative;
        width: 100%; /* Change position to static */
        max-height: 0; /* Start with max-height of 0 for smooth transition */
        overflow: hidden;
        text-align: center; /* Hide overflow for transition effect */
    }

    .dropdownMenuOpen {
        max-height: 300px; /* Set a max-height when the menu is open */
    }

    .dropdownMenu a {
        padding: 15px 20px; /* Increased padding for better touch targets */
    }
}
