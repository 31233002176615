.notificationDiv {
    width: 100%;
    min-height: 75vh;
    box-shadow: 2px 2px 5px black;
    /*background: linear-gradient(to bottom right, #384d66, #08182c);*/
    background-color: var(--navbar-grey);
}

.notificationDiv h1 {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #eee;
}

.noFriend {
    color: #ccc;
    text-align: center;
}

.request {
    width: 100%;
    margin-left: 0%;
    padding-top: 30px;
    height: 100px;
    text-align: center;
    /*background: linear-gradient(to right, #384d66, #08182c);*/
    background-color: var(--dark-grey);
    color: #eee;
}

.request:nth-child(even) {
    /*background:  linear-gradient(to left, #384d66, #08182c);*/
    background-color: var(--navbar-grey);
}

.request p {
    display: inline-flex;
    margin-bottom: 10px;
}

.notificationDiv button {
    width: 25%;
    height: 40px;
    /*background-color: #08182c;*/
    background-color: var(--link-blue);
    padding: 0;
    margin: 0 5px;
    border: 0;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    color: #eee;
    text-decoration: none;
    transition: background-color 0.2s;
    text-decoration: none;
    font-size: 1.2rem;
    border-radius: 0.2rem;
}

.notificationDiv button:hover {
    /*background-color: #384d66;*/
    background-color: var(--link-blue-hover);
    text-decoration: none;
}

.profileLink {
    color: var(--link-blue);
}

.profileLink:hover {
    color: var(--link-blue-hover)
}

.notificationDiv .backProfileLinkDiv {
    padding-top: 25px;
    padding-left: 25px;
}

.backProfileLink {
    width: 150px;
    height: 40px;
    /*background-color: #08182c;*/
    background-color: var(--link-blue);
    padding: 0;
    margin-left: 0px;
    margin: 0;
    border: 0;
    display: block;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    color: white;
    text-decoration: none;
    transition: background-color 0.2s;
}

.backProfileLink:hover {
    /*background-color: #384d66;*/
    background-color: var(--link-blue-hover);
    text-decoration: none;
}

@media (max-width: 800px) {
    .backProfileLink {
        margin: 0 auto;
    }

    .notificationDiv .backProfileLinkDiv {
        padding-left: 0px;
    }

    .request {
        height: 150px;
    }

    .request p {
        display: block;
    }
    
}