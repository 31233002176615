.discussionContainer {
    padding: 20px;
    color: #f0f0f0;
}

.discussionList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.discussionItem {
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: #171a21;
    cursor: pointer;
    transition: background-color 0.3s;
}

.discussionItem:hover {
    background-color: #3a3a3a;
}

.discussionItem h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
}

.discussionItem p {
    margin: 0;
    font-size: 14px;
}

.additionalInfo {
    display: inline-flex;
    width: 25%;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.9em;
    color: #888;
    margin-left: 15px;
}


.discussionForm {
    display: flex;
}

.genres {
    width: 70%;
    display: inline-flex;
}

.genre {
    display: inline-block;
    padding: 6px 12px;
    background-color: #333;
    border-radius: 4px;
    font-size: 14px;
    color: #f0f0f0;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
    border: 1px solid transparent;
    margin-right: 5px;
}

.genre:hover {
    background-color: #5c5c5c;
    border: 1px solid #61dafb;
    color: #61dafb;
}

.genre:active {
    background-color: #666;
    border: 1px solid #61dafb;
    color: #61dafb;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pageButton {
    padding: 10px 15px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: #444;
    color: #eee;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.pageButton:hover {
    background-color: #555;
}

.pageButton:disabled {
    background-color: #333;
    cursor: not-allowed;
}

.pageButton.active {
    background-color: #007bff;
    color: white;
}

.active {
    background-color: #777;
    border: 1px solid #61dafb;
    color: #61dafb;
}


.search {
    background-color: rgba(0, 0, 0, 0.5);
    width: 60%;
    min-height: 45px;
}

.search input {
    height: 30px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    margin-left: 7.5px;
    border-radius: 5px;
}

.searchBar {
    color: #eee;
    background-color: #213a4c;
    background-color: var(--lighter-purple);
    border: none;
    font-size: 14px;
    padding: 5px;
}

.searchBar:focus {
    border: none;
    outline: 2px solid grey;
}

.searchBtn {
    width: 50px;
    /*background-color: #213a4c;
    color: #5fb2e2;*/
    background-color: var(--link-blue);
    color: #eee;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-right: 50px;
}

.searchBtn:hover {
    /*background-color: #5fb2e2;*/
    background-color: var(--link-blue-hover);
    color: #eee;
}

.activeFilters {
    line-height: 45px;
    padding-left: 10px;
    color: #5fb2e2;
}

.activeGenre:hover {
    color: #e05656;
}

.clearFilters {
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.clearFilters:hover {
    background-color: #e05656;
}

.clearFilters:active {
    background-color: #c44545;
}

.searchCreateContainer {
    display: flex;
}

.createDiscussionContainer {
    width: 20%;
    height: 40px;
    margin-left: 12.5%;
}

.createDiscussionBtn {
    width: 150px;
    height: 45px;
    /*background-color: #213a4c;
    color: #5fb2e2;*/
    background-color: var(--link-blue);
    color: #eee;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

.createDiscussionBtn:hover {
    /*background-color: #5fb2e2;*/
    background-color: var(--link-blue-hover);
    color: #eee;
}


@media (max-width: 1200px) {
    .search {
        width: 80%;
    }

    .searchBar {
        width: 30%;
    }
}

@media (max-width: 1050px) {
    .searchCreateContainer {
        display: block;
        min-height: 10px;
    }

    .createDiscussionContainer {
        margin: 10px 0;
    }
}

@media (max-width: 667px) {
    .search {
        width: 100%;
    }

    .searchBar {
        width: 50%;
    }

    .genres {
        width: 100%;
    }

    .additionalInfo {
        width: 100%;
        padding-right: 10px;
    }

}

@media (max-width: 450px) {
    .search {
        width: 100%;
    }

    .searchBar {
        width: 60%;
    }

    .discussionItem {
        height: auto;
    }

    .discussionContainer {
        padding: 0px;
    }

    .pageButton {
        padding: 8px;
        font-size: 12px;
    }

    .createDiscussionContainer {
        width: 100%;
    }

    .createDiscussionBtn {
        margin-left: 25%;
        width: 50%;
    }

    .discussionH2 {
        text-align: center;
    }
}