.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background: var(--navbar-grey);
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    color: #eee;
}

.modalButtons {
    margin-top: 10px;
}

.modalButton {
    padding: 5px 10px;
    background-color: var(--link-blue);
    outline: none;
    border: none;
    cursor: pointer;
    color: #eee;
}

.modalButton:hover {
    background-color: var(--link-blue-hover);
}

button {
    margin: 5px;
}

.sellCardH2 {
    color: #eee;
}

.modalContent h2 {
    color: var(--light-blue);
}
