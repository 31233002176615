.createDiscussionContainer {
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 8px;
    color: #eee;
}

.createDiscussionContainer h2 {
    color: #61dafb;
}

.createDiscussionForm {
    display: flex;
    flex-direction: column;
}

.createDiscussionForm input,
.createDiscussionForm textarea {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1em;
    color: #eee;
    background-color: #2a2a2a;
    border: none;
    font-size: 14px;
    border-radius: 8px;
}

.createDiscussionForm select {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1em;
    background-color: #2a2a2a;
    min-height: 150px;
    border: none;
    color: #eee;
}

.createDiscussionForm select option {
    margin-bottom: 5px;
    font-size: 1em;
}


.submitButton {
    padding: 10px 20px;
    /*background-color: #3498db;*/
    background-color: var(--link-blue);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submitButton:hover {
    /*background-color: #2980b9;*/
    background-color: var(--link-blue-hover);
}

.backButton {
    margin: 0;
    margin-bottom: 20px;
    padding: 10px 20px;
    /*background-color: #213a4c;*/
    background-color: var(--link-blue);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

.backButton:hover {
    /*background-color: #0092bb;*/
    background-color: var(--link-blue-hover);
}

.italic {
    font-style: italic;
    font-size: 12px;
}

.createDiscussionForm label input {
    margin-left: 5px;
}

@media (max-width: 667px) {
    .createDiscussionContainer {
        width: 90%;
    }
}

@media (max-width: 450px) {
    .createDiscussionContainer {
        width: 100%;
    }

    .createDiscussionForm select option {
        padding-bottom: 15px;
        padding-top: 15px;
        height: 50px;
        text-align: center;
    }

    .backButton {
        float: none;
    }

    .multipleSelect {
        min-height: 50px;
    }
}
