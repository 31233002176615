.marketContainer {
    width: 100%;
    min-height: 75vh;
}

.marketContainer h1 {
    color: #eee;
}


.marketItem {
    width: 100%;
    min-height: 150px;
    background-color: #171a21;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: #eee;
}

.itemImage {
    width: 100px;
    height: auto;
    margin-right: 20px;
}

.itemDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    text-align: center;
}

.itemName {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
}

.itemPrice,
.sellerName {
    font-size: 0.9em;
}

.buyButton {
    width: 75px;
    height: 35px;
    background-color: #5fb2e2;
    color: #171a21;
    background-color: var(--link-blue);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.25s, color 0.15s;
}

.buyButton:hover {
    /*background-color: #4a9cc3;*/
    background-color: var(--link-blue-hover);
    color: white;
}




.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pageButton {
    padding: 10px;
    margin: 0 5px;
    background-color: #444;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.pageButton:disabled {
    background-color: #333;
    cursor: not-allowed;
}

.pageButton:hover {
    background-color: #555;
}

.pageButton.active {
    background-color: #007bff;
    color: white;
}


.search {
    background-color: rgba(0, 0, 0, 0.5);
    width: 60%;
    min-height: 45px;
}

.search input {
    height: 30px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    margin-left: 7.5px;
    border-radius: 5px;
}

.searchBar {
    color: #eee;
    /*background-color: #213a4c;*/
    background-color: var(--lighter-purple);
    border: none;
    font-size: 14px;
    padding: 5px;
}

.searchBar:focus {
    border: none;
    outline: 2px solid var(--light-grey);
}

.searchBtn {
    width: 50px;
    /*background-color: #213a4c;*/
    background-color: var(--link-blue);
    color: #eee;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.searchBtn:hover {
    background-color: #5fb2e2;
    background-color: var(--link-blue-hover);
    color: #eee;
}

.search select {
    background-color: #213a4c;
    border: none;
    cursor: pointer;
    height: 30px;
    border-radius: 5px;
    width: 100px;
    margin-left: 50px;
    padding-left: 5px;
    color: #eee;
    margin-right: 10px;
}

.search select:focus {
    outline: 1px solid #5fb2e2;
}

.activeFilters {
    line-height: 45px;
    padding-left: 10px;
    color: var(--light-blue);
}

.noItemsMessage {
    color: #eee;
    margin-left: 5px;
    margin-top: 5px;
}



@media (max-width: 1200px) {
    .search {
        width: 80%;
    }

    .searchBar {
        width: 30%;
    }
}

@media (max-width: 667px) {
    .search {
        width: 100%;
    }

    .searchBar {
        width: 50%;
    }
}

@media (max-width: 450px) {
    .search {
        width: 100%;
    }

    .searchBar {
        width: 60%;
    }

    .itemDetails {
        width: 100%;
    }

    .itemImage {
        margin-right: 0px;
    }

    .buyButton {
        width: 100px;
        height: 50px;
    }

    .marketH1 {
        text-align: center;
        margin-bottom: 15px;
    }
}