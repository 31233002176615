:root {
  --black: #0B0C10;
  --dark-grey: #1F2833;
  --light-grey: #C5C6C7;
  --light-blue: #66FCF1;
  --turquoise: #45A29E;
  --link-blue: #3b82f6;
  --link-blue-hover: #2563eb;
  --dark-purple: #120346;
  --lighter-purple: #15152F;
  --navbar-grey: #171A21;
  --medium-grey: #2E2E2E;
}

body {
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body::after {
  content: '';
  display: block;
  min-height: 150px;
  /* Set same as footer's height */
}


@media (max-width: 667px) {
  body::after {
    content: '';
    display: block;
    min-height: 250px;
    /* Set same as footer's height */
  }
}
