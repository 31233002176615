.gameSessionContainer {
    text-align: center;
    color: #ccc;
}

.progressCircleContainer {
    margin: 20px auto;
    position: relative;
    width: 150px;
    height: 150px;
}

.progressCircle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.circleFill {
    position: absolute;
    width: 120px;
    height: 120px;
    background-color: #171a21;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: #ccc;
    border: 2px solid #171a21;
}

.playButton, .backButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: #eee;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.playButton:hover, .backButton:hover {
    background-color: #218838;
}

.backButton {
    margin-left: 10px;
    background-color: #007bff;
}

.backButton:hover {
    background-color: #0056b3;
}

.cardReward {
    font-size: 1.5rem;
    font-weight: bold;
}
