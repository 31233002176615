/* Existing CSS */

.libraryContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    /*background-color: #1b2838;*/
    background-color: var(--navbar-grey);
    color: #c7d5e0;
    height: 80vh;
}

.gameList {
    width: 30%;
    border-right: 1px solid #394b59;
    padding-right: 15px;
}

.gameList h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #c7d5e0;
}

.gameList ul {
    list-style-type: none;
    padding: 0;
    max-height: 70vh;
    overflow-y: auto;
}

.gameItem {
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid #394b59;
    font-size: 1.2em;
    transition: background-color 0.2s ease;
    position: relative;
    display: flex; /* Ensures flex layout on all screen sizes */
    align-items: center;
    justify-content: space-between;
}

.gameItem:hover {
    background-color: #3e4b59;
}

.gameDetails {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*background-color: #2a3a4b;*/
    background-color: var(--dark-grey);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

.gameDetails h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #ffffff;
}

.playButton {
    padding: 12px 30px;
    background-color: #5cb85c;
    color: white;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.playButton:hover {
    background-color: #4cae4c;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .libraryContainer {
        flex-direction: column;
        align-items: center;
    }

    .gameList {
        width: 100%;
        border: none;
        padding: 0;
    }

    .gameDetails {
        display: none; /* Hide the game details section on mobile */
    }

    .gameItem {
        padding: 15px;
        display: flex;
        justify-content: space-between;
    }

    .playButtonMobile {
        margin-left: auto;
        padding: 8px 20px;
        background-color: #5cb85c;
        color: white;
        font-size: 1em;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .playButtonMobile:hover {
        background-color: #4cae4c;
    }
}
