/* EditDiscussion.module.css */

.editDiscussionContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 10px;
    color: #f1f1f1;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.editDiscussionContainer h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #61dafb;
}

.formGroup {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.formGroup label {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 5px;
    color: #dcdcdc;
}

.formGroup input,
.formGroup textarea {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #333;
    background-color: #2a2a2a;
    color: #f1f1f1;
    font-size: 1rem;
    outline: none;
    resize: vertical;
    margin-bottom: 15px;
}

.formGroup input[type="checkbox"] {
    width: auto;
    margin-left: 10px;
}

.formGroup input:focus,
.formGroup textarea:focus {
    border: 1px solid #61dafb;
}

.formGroup input::placeholder,
.formGroup textarea::placeholder {
    color: #7a7a7a;
}

.formGroup select {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1em;
    background-color: #2a2a2a;
    min-height: 150px;
    border: none;
    color: #eee;
}

.formGroup select option {
    margin-bottom: 5px;
    font-size: 1em;
}

.submitButton {
    background-color: #61dafb;
    color: #1e1e1e;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #0092bb;
}

.backButton {
    margin: 0;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: var(--link-blue);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

.backButton:hover {
    background-color: var(--link-blue-hover);
}

@media (max-width: 450px) {
    .backButton {
        float: none;
        width: 100%;
        margin-bottom: 10px;
    }

    .formGroup input,
    .formGroup textarea {
        font-size: 0.9rem;
    }

    .submitButton {
        width: 100%;
    }
}
