.profileCard {
    background-color: #171a21;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 150px;
}

.profileCard img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.profileCard h4 {
    margin: 0;
    font-size: 1.1em;
    color: #eee;
}

.profileCard p {
    margin: 5px 0;
    font-size: 0.9em;
    color: #777;
}
