.socialBackground {
    padding: 20px;
    width: 100%;
    min-height: 600px;
    box-shadow: 2px 2px 5px black;
    /*background: linear-gradient(to bottom right, #384d66, #08182c);*/
    background-color: var(--navbar-grey);
}

.searchBar {
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    /*background-color: #213a4c;*/
    background-color: var(--dark-grey);
    outline: 1px solid #ccc;
    border: none;
    color: #eee;
    border-radius: 4px;
}

.searchH2 {
    color: #eee;
}

.searchBar:focus {
    border: none;
    outline: 2px solid var(--light-blue);
}

.searchResults ul {
    list-style: none;
    padding: 0;
}

.userItem {
    background-color: var(--light-grey);
    border: 1px solid #333;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.userItem:hover {
    background-color: #f0f0f0;
}

.userLink {
    color: black;
    width: 100%;
    display: inline-block;
}

.userLink:hover {
    color: black;
}

.whiteColor {
    color: #eee;
}