.gemenskap {
    width: 44%;
    /*background-color: #213a4c;*/
    background-color: var(--navbar-grey);
    color: var(--light-grey);
    margin-top: 15px;
    text-align: center;
    display: inline-block;
}

.gemenskap img {
    width: 300px;
    height: 250px;
    object-fit: contain;
    display: block;
    margin: 0 auto;
    margin-top: 25px;
}

.gemenskap:nth-child(odd) {
    margin-left: 5%;
}

.gemenskap:nth-child(even) {
    margin-right: 5%;
    border-left: 1px solid #1b2838;
}

.picBtn {
    display: block;
    width: 200px;
    height: 50px;
    font-size: 16px;
    /*background-color: #213a4c;
    color: #5fb2e2;*/
    background-color: var(--link-blue);
    color: #eee;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    margin: 0 auto;
}

.picBtn:hover {
    /*background-color: #5fb2e2;
    color: #eee;*/
    background-color: var(--link-blue-hover);
    color: #eee;
}

.gemenskapH2 {
    text-align: center;
    color: #eee;
}

.gameDescH2Border {
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #1b2838, #eee, #1b2838);
    margin-bottom: 10px;
}

.dateText {
    margin-bottom: 10px;
}


@media (max-width: 800px) {
    .gemenskap {
        width: 90%;
        display: block;
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media (max-width: 375px) {
    .gemenskap {
        width: 100%;
        display: block;
        margin-left: 0%;
        margin-right: 0%;
    }
    .gemenskap img {
        width: 250px;
        height: 200px;
        object-fit: contain;
    }

    .gemenskap:nth-child(odd) {
        margin-left: 0%;
    }
    
    .gemenskap:nth-child(even) {
        margin-right: 0%;
        border-left: 0px solid #1b2838;
    }
}