/* Chat.module.css */

.container {
  display: flex;
  flex-direction: column;
  height: 85vh;
  padding: 20px;
  background-color: #171a21;
}

.header {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: #eee;
}

.backButton {
  margin: 0;
  padding: 10px 20px;
  background-color: var(--link-blue);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
}

.backButton:hover {
  background-color: var(--link-blue-hover);
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #eee;
  background-color: #171a21;
}

.message {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
  hyphens: auto;
}

.message.sender {
  background-color: #262729;
}

.message.receiver {
  background-color: #384d66;
}

.inputContainer {
  display: flex;
  margin-top: 10px;
}

.input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #171a21;
  color: #eee;
  outline: none;
}

.input:focus {
  border-color: #007bff;
}

.sendButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0px;
}

.sendButton:hover {
  background-color: #0056b3;
}

.showMoreButton {
  width: 100%;
  margin: 0;
  height: 25px;
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.showMoreButton:hover {
  background-color: #0056b3;
}



@media (max-width: 450px) {
  .container {
    padding: 0px;
    height: 70vh;
    padding-bottom: 15px;
  }

  .header {
    margin-left: 15px;
    padding-top: 15px;
  }

  .sendButton {
    padding: 10px 40px;
    margin-right: 10px;
  }

  .backButton {
    margin-right: 15px;
  }
}

@media (max-width: 450px) {

  .input {
    flex-grow: 0;
    width: 70%;
  }
  .sendButton {
    padding: 10px 2%;
    width: 25%;
    margin-right: 10px;
  }
}