* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    line-height: 1.4;
    font-family: sans-serif;
    background-color: var(--lighter-purple);
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
}

h1 {
    font-size: 1.8rem;
}

h2 {
    font-size: 1.6rem;
    margin-bottom: 1.4rem;
}

p {
    font-size: 1rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
}

.header {
    position: relative;
    background-image: url("./banner.jpg");
    background-size: cover;
    background-position: center;
}

.bannerImage {
    display: block;  /* Ensure the image behaves correctly in the layout */
    width: 100%;  /* Make it responsive */
    height: auto;
}
  
.header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
/* Style for the overlay text */
.overlayText {
position: absolute;
top: 32%;
left: 50%;
transform: translate(-50%, -50%);
color: var(--light-blue); /* Light blue color from your color palette */
font-size: 2em;
font-weight: bold;
text-align: center;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9); /* Add shadow for better readability */
}

.overlayText p {
font-size: 1em;
}

.overlayText span {
color: var(--turquoise);
}

.paintCanvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; /* Ensure it’s above the banner but below overlay text */
    background-color: transparent;
    cursor: pointer;
}

/*.header {
    position: relative;
    width: 100%; /* Adjust the height as desired */
/*    overflow: hidden;
  }
  
  .header img {
    width: 100%;
    height: 100%;
    opacity: 0.75;
    object-fit: cover;
  }*/

.search {
    background-color: rgba(0, 0, 0, 0.5);
    width: 60%;
    height: 45px;
}

.search input {
    height: 30px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    margin-left: 7.5px;
    border-radius: 5px;
}

.searchText {
    color: #eee;
    margin-top: 25px;
    padding: 0;
}

main {
    margin: 0 auto;
    max-width: 1200px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.searchBar {
    color: #eee;
    background-color: #213a4c;
    border: none;
    font-size: 14px;
}

.searchBar:focus {
    border: none;
    outline: 2px solid grey;
}

.searchBtn {
    width: 50px;
    background-color: #213a4c;
    color: #5fb2e2;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.searchBtn:hover {
    background-color: #5fb2e2;
    color: #eee;
}

.gameListH3 {
    font-size: 22px;
    margin-top: 25px;
    color: #eee;
}

.prevPage {
    width: 100px;
    height: 50px;
    color: #eee;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    font-size: 36px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s, border-radius 0.3s;
}

.prevPage:hover {
    box-shadow: -40px 0 10px -5px inset rgba(255, 255, 255, 0.5);
    border-radius: 0 15px 15px 0;
}

.nextPage {
    width: 100px;
    height: 50px;
    color: #eee;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    font-size: 36px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    float: right;
    clear: both;
    transition: box-shadow 0.3s, border-radius 0.3s;
}

.nextPage:hover {
    box-shadow: 40px 0 10px -5px inset rgba(255, 255, 255, 0.5);
    border-radius: 15px 0 0 15px;
}

.search select {
    background-color: #213a4c;
    border: none;
    cursor: pointer;
    height: 30px;
    border-radius: 5px;
    width: 100px;
    margin-left: 50px;
    padding-left: 5px;
    color: #eee;
    margin-right: 10px;
}


.search select:focus {
    outline: 1px solid #5fb2e2;
}

@media (max-width: 1200px) {
    .search {
        width: 80%;
    }
    .searchText {
        margin-left: 5px;
    }

    .gameListH3 {
        margin-left: 5px;
    }

    .searchBar {
        width: 30%;
    }
}

@media (max-width: 667px) {
    .gameListH3 {
        font-size: 22px;
        margin-top: 75px;
        margin-bottom: 10px;
        color: #eee;
        margin-left: 5px;
    }

    .search {
        width: 100%;
    }

    .search select {
        margin-left: 150px;
    }

    .overlayText {
        font-size: 1.5rem;
    }
}

@media (max-width: 465px) {
    .search {
        width: 100%;
    }

    .searchBar {
        width: 60%;
    }

    .search select {
        margin-left: 10px;
    }

    .overlayText {
        display: none;
    }
}

@media (max-width: 463px) {
    .search {
        height: 100px;
    }
}

