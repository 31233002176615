/*.both {
    width: 100%;
    height: 400px;
    box-shadow: 2px 2px 5px black;
    cursor: pointer;
    background: linear-gradient(to bottom right, #384d66, #08182c);
}

.gameName {
    font-size: 1.0em;
    color: #eee;
    text-align: center;
}

.gamePic {
    width: 100%;
    height: 340px;
    margin-top: 30px;
    box-shadow: 5px 5px 5px black;
    object-fit: cover;
    border: 1px solid black;
    transition: transform 0.3s;
}

.gamePic:hover {
    transform: scale(1.05);
}

.smallScreenshot {
    width: 40%;
    border: 1px solid black;
    object-fit: cover;
}

.leftDiv {
    width: 50%;
    height: 400px;
    float: left;
    margin-left: 5%;
}

.rightTopDiv {
    padding-top: 20px;
    height: 30px;
    width: 45%;
    float: right;
}

.rightDiv {
    --g: 2px;
    --s: 370px;

    width: 45%;
    height: 370px;
    display: grid;
    place-content: center;
}

.rightDiv > img {
    grid-area: 1/1;
    width: 300px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    transform: translate(var(--_x, 0), var(--_y, 0));
    cursor: pointer;
    z-index: 0;
    transition: 0.3s, z-index 0s 0.3s;
}

.rightDiv img:hover {
    --_i: 1;

    z-index: 1;
    transition: transform 0.2s, clip-path 0.3s 0.2s, z-index 0s;
}

.rightDiv:hover img {
    transform: translate(0, 0);
}

.rightDiv > img:nth-child(1) {
    clip-path: polygon(50% 50%, calc(50% * var(--_i, 0)) calc(120% * var(--_i, 0)), 0 calc(100% * var(--_i, 0)), 0 0, 100% 0, 100% calc(100% * var(--_i, 0)), calc(100% - 50% * var(--_i, 0)) calc(120% * var(--_i, 0)));

    --_y: calc(-1 * var(--g));
}

.rightDiv > img:nth-child(2) {
    clip-path: polygon(50% 50%, calc(100% - 120% * var(--_i, 0)) calc(50% * var(--_i, 0)), calc(100% - 100% * var(--_i, 0)) 0, 100% 0, 100% 100%, calc(100% - 100% * var(--_i, 0)) 100%, calc(100% - 120% * var(--_i, 0)) calc(100% - 50% * var(--_i, 0)));

    --_x: var(--g);
}

.rightDiv > img:nth-child(3) {
    clip-path: polygon(50% 50%, calc(100% - 50% * var(--_i, 0)) calc(100% - 120% * var(--_i, 0)), 100% calc(100% - 120% * var(--_i, 0)), 100% 100%, 0 100%, 0 calc(100% - 100% * var(--_i, 0)), calc(50% * var(--_i, 0)) calc(100% - 120% * var(--_i, 0)));

    --_y: var(--g);
}

.rightDiv > img:nth-child(4) {
    clip-path: polygon(50% 50%, calc(120% * var(--_i, 0)) calc(50% * var(--_i, 0)), calc(100% * var(--_i, 0)) 0, 0 0, 0 100%, calc(100% * var(--_i, 0)) 100%, calc(120% * var(--_i, 0)) calc(100% - 50% * var(--_i, 0)));

    --_x: calc(-1 * var(--g));
}

.rightDivTwoImgs {
    --g: 2px;
    --s: 370px;

    width: 45%;
    height: 370px;
    display: grid;
    place-content: center;
}

.rightDivTwoImgs > img {
    grid-area: 1/1;
    width: 300px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    transform: translate(var(--_x, 0), var(--_y, 0));
    cursor: pointer;
    z-index: 0;
    transition: 0.3s, z-index 0s 0.3s;
}

.rightDivTwoImgs img:hover {
    --_i: 1;

    z-index: 1;
    transition: transform 0.2s, clip-path 0.3s 0.2s, z-index 0s;
}

.rightDivTwoImgs img {
    transform: translate(0, 0);
}

.rightDivTwoImgs > img:first-child {
    clip-path: polygon(0% 100%, calc(0% * var(--_i, 0)) calc(120% * var(--_i, 0)), 0 calc(100% * var(--_i, 0)), 0 0, 100% 0, 100% calc(100% * var(--_i, 0)), calc(100% - 50% * var(--_i, 0)) calc(120% * var(--_i, 0)));

    --_y: calc(-1 * var(--g));
}

.rightDivTwoImgs > img:last-child {
    clip-path: polygon(0% 100%, calc(100% - 120% * var(--_i, 0)) calc(50% * var(--_i, 0)), calc(100% - 100% * var(--_i, 0)) 0, 100% 0, 100% 100%, calc(00% - 100% * var(--_i, 0)) 100%, calc(100% - 120% * var(--_i, 0)) calc(100% - 50% * var(--_i, 0)));

    --_x: var(--g);
}

.rightDivOneImg {
    width: 45%;
    height: 370px;
    display: grid;
    place-content: center;
}

.rightDivOneImg > img {
    grid-area: 1/1;
    width: 300px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
}

.rightDivOneImg > img:first-child {
    clip-path: polygon(0);
}


@media (max-width: 667px) {
    .leftDiv {
        width: 90%;
        height: 350px;
    }

    .gamePic {
        height: 300px;
    }

    .rightDiv {
        display: none;
    }

    .rightTopDiv {
        width: 100%;

    }

    .gameName {
        font-size: 1.0em;
    }
}*/

/* Container card styling */
.card {
    width: 230px;
    background-color: #171A21;
    color: #fff;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

/* Image styling */
.imageWrapper {
    position: relative;
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
}

/* Content styling */
.cardContent {
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.cardFooter {
    padding: 16px;
    text-align: center;
}
  
  .card:hover .backgroundImage {
    transform: scale(1.1);
  }

  .card:hover .seeMore {
    background-color: #2563eb;
  }
  
  /* Overlay effect on hover */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  .card:hover .overlay {
    opacity: 1;
  }
  
  /* Information section styling */
  .info {
    padding: 15px;
  }
  
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #ffffff;
    text-align: center;
  }
  
  .date, .rating {
    font-size: 14px;
    color: #9ca3af;
    margin-top: 4px;
    text-align: center;
  }
  
  /* Button styling */
  .seeMore {
    margin-top: 12px;
    padding: 8px 12px;
    width: 100%;
    background-color: #3b82f6;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .seeMore:hover {
    background-color: #2563eb;
  }
  