.container {
    display: flex;
    flex-direction: column;
    height: 85vh;
    padding: 20px;
    background-color: var(--lighter-purple);
  }

.container h1 {
    color: #eee;
    text-align: center;
    margin-bottom: 15px;
}

.friendList {
    list-style-type: none;
    padding: 0;
}

.friendContainer {
    position: relative;
    display: inline-block;
    min-width: 200px;
}

.friend {
    display: inline-flex;
    justify-content: space-between;
    height: 50px;
    background-color: #171a21;
    min-width: 200px;
    margin-right: 25px;
    margin-bottom: 10px;
}

.friendLink {
    margin-right: 10px;
    text-decoration: none;
    color: #eee;
    line-height: 50px;
}

.online {
    color: green;
}

.offline {
    color: red;
}

.profileCardContainer {
    position: absolute;
    display: flex;
    top: 0;
    left: -150px; /* Adjust to the left */
    z-index: 100;
}

.chatButton {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 25px;
    transition: background-color 0.3s;
}

.chatButton:hover {
    background-color: #0056b3;
}

.removeButton {
    background: none;
    border: none;
    color: red;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 10px;
    z-index: 20;
}

.removeButton:hover {
    color: darkred;
}

.backButton {
    width: 200px;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: var(--link-blue);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
  }
  
  .backButton:hover {
    background-color: var(--link-blue-hover);
  }

@media (max-width: 1500px) {
    .profileCardContainer {
        left: 200px;
        right: 0;
    }
}


@media (max-width: 800px) {
    .friendContainer {
        width: 100%;
    }

    .friend {
        width: 40%;
    }

    .profileCardContainer {
        left: 100%;
        right: 0;
    }
    
}

@media (max-width: 600px) {
    .friend {
        width: 100%;
    }

    .profileCardContainer {
        left: 0px;
        right: 0;
        top: 50px;
    }
    
}

